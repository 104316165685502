import React, { Fragment, useEffect, useState } from 'react'
import { HashRouter as Router, Route, useHistory, Redirect } from 'react-router-dom'

import LoginScreen from './screens/LoginScreen'
import NewLoginScreen from './screens/MWFBI/Login'
import HomeScreen from './screens/HomeScreen/HomeScreen'
import AboutScreen from './screens/AboutScreen/AboutScreen'
import MWFBIHomeScreen from './screens/MWFBI/HomeScreen/HomeScreen'
import FarmBureauFederation from './screens/MWFBI/FarmBureauFederation'
import MWFBIAboutScreen from './screens/MWFBI/AboutScreen/AboutScreen'
import Faq from './screens/MWFBI/FAQ'
import Careers from './screens/MWFBI/Careers'
import LearningCenter from './screens/MWFBI/LearningCenter'
import LearningCenterDetails from './screens/MWFBI/LearningCenter/LearningCenterDetails'
import MWFBIHowToFileClaimScreen from './screens/MWFBI/FileClaim/HowToFileClaimScreen'
import FindAnAgent from './screens/MWFBI/FindAnAgent/FindAnAgent'
import FindAnAgentDetail from './screens/MWFBI/FindAnAgent/FindAnAgentDetail'
import StartAQuote from './screens/MWFBI/StartAQuote/StartAQuote'
import OurCommunity from './screens/MWFBI/OurCommunity/OurCommunity'
import AboutUs from './screens/MWFBI/AboutUs/AboutUs'
import AgentDescription from './screens/MWFBI/FindAnAgent/AgentDescription'

//phase 2
import AutoInsurance from './screens/MWFBI/AutoInsurance/index'
import BusinessInsurance from './screens/MWFBI/BusinessInsurance/index'
import HomeOwnersInsurance from './screens/MWFBI/HomeOwnersInsurance/index'
import Annuities from './screens/MWFBI/Annuities'
import LifeInsurance from './screens/MWFBI/LifeInsurance/index'
import OtherOfferings from './screens/MWFBI/OtherOfferings/index'
import FarmRanchInsurance from './screens/MWFBI/Farm&RanchInsurance'
import LandingScreen from './screens/MWFBI/Landing'
import './App.css'
import ContactUs from './screens/MWFBI/ContactUs/ContactUs'
import ERS from './screens/MWFBI/ERS/ERS'
import { getAgentList } from './redux/actions/agentListActions'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import InternalLandingScreen from './screens/MWFBI/InternalLanding'
import TermsConditions from './screens/MWFBI/TermsConditions/TermsConditions'
import PrivacyPolicy from './screens/MWFBI/PriavcyPolicy/PrivacyPolicy'
import { azureLogin } from './redux/actions/userActions'
import { USER_AZURE_LOGOUT, USER_AZURE_SUCCESS } from './redux/constants/userConstants'
import AgentLogin from './screens/MWFBI/AgentLogin'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import AgentLandingScreen from './screens/MWFBI/Landing/AgentLandingScreen'
import AgentLanding from './screens/MWFBI/Landing/AgentLandingScreenLatest'
import CustomerHub from './screens/MWFBI/Landing/CustomerHub'
import CustomerLogin from './screens/MWFBI/CustomerLogin'
import BlogSettings from './screens/MWFBI/BlogSettings'
import EditBlog from './screens/MWFBI/EditBlog'
import { ADMIN_USERS } from './redux/actions/ip'
import Preview from './screens/MWFBI/Preview/Preview'
import MaintenancePage from './screens/MWFBI/MaintenancePage'

// import RadioScript from './screens/SalesMarketing/RadioScript';
// import HomeScreen360 from './screens/360Insurance/HomeScreen/HomeScreen';
// import MeetingHomeScreen from './screens/Meeting/HomeScreen/HomeScreen';
// import Annuities from './screens/MWFBI/Annuities';
let scaleValue = 1
if (isMobile) document.body.style.zoom = 1
else {
}

// switch (window.devicePixelRatio) {
//   case  1.25:
//     scaleValue = 0.8
//     break
//   case 1.5:
//     scaleValue = 1
//     break
//   case 1.75:
//     scaleValue = 1.1
//     break
// }
document.body.style.zoom = scaleValue
document.body.style['-ms-zoom'] = scaleValue
document.body.style['-webkit-zoom'] = scaleValue
document.body.style['-ms-zoom'] = scaleValue

// const TRACKING_ID = "UA-60364536-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
const App = () => {
  const dispatch = useDispatch();
  const history = useHistory()

  const azureLogin = useSelector((state) => state.azureLogin);
  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    dispatch(getAgentList())
    if (process.env.REACT_APP_NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_EDIT === 'YES') {
      dispatch({
        type: USER_AZURE_SUCCESS,
        payload: { account: { username: 'piyoosh@mwfbi.com', accessToken: 'token', name: 'Developer' } }
      });
      localStorage.setItem('az', JSON.stringify({ account: { username: 'piyoosh@mwfbi.com', accessToken: 'token', name: 'Developer' } }))
    } else if (process.env.REACT_APP_NODE_ENV === 'development') {
      dispatch({ type: USER_AZURE_LOGOUT });
    }
  }, [])
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setIsAdmin(true)
      } else setIsAdmin(false)
    } else setIsAdmin(false)
  }, [azureLogin])
  return (
    <Fragment>
      <Router>
        <Route exact path='/main' component={HomeScreen} />
        <Route exact path='/about' component={AboutScreen} />
        <Route exact path='/mwfbi/find-an-agent' component={FindAnAgent} />
        <Route
          exact
          path='/mwfbi/find-an-agent-detail'
          component={FindAnAgentDetail}
        />

        <Route exact path='/' component={MWFBIHomeScreen} />
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/login2' component={NewLoginScreen} />
        <Route exact path='/agent-login' component={AgentLogin} />
        <Route exact path='/customer-login' component={CustomerLogin} />
        {
          isAdmin &&
          <Route exact path='/mwfbi/blog-settings' component={BlogSettings} />
        }

        <Route exact path='/mwfbi/about' component={MWFBIAboutScreen} />
        <Route exact path='/mwfbi/ers' component={ERS} />
        <Route
          exact
          path='/mwfbi/how-to-claim-file'
          component={MWFBIHowToFileClaimScreen}
        />

        <Route exact path='/mwfbi/faq' component={Faq} />
        <Route exact path='/mwfbi/careers' component={Careers} />
        <Route
          exact
          path='/mwfbi/learning-center'
          component={MWFBIHomeScreen}
        />

        <Route
          exact
          path='/mwfbi/farm-bureau-federation'
          component={FarmBureauFederation}
        // errorElement={<ErrorBoundary />}
        />

        <Route exact path='/mwfbi/start-a-quote/:id?' component={StartAQuote} />
        <Route
          exact
          path='/mwfbi/about/our-community'
          component={OurCommunity}
        />
        <Route exact path='/mwfbi/about/careers' component={Careers} />
        <Route
          exact
          path='/mwfbi/about/blog'
          component={LearningCenter}
        />
        {
          isAdmin &&
          <Route
            exact
            path='/mwfbi/blog-editor/:id?'
            component={EditBlog}
          />
        }
        <Route
          exact
          path='/mwfbi/about/blog/:id'
          component={LearningCenterDetails}
        />
        <Route
          exact
          path='/mwfbi/about/farm-bureau-federation'
          component={FarmBureauFederation}
          errorElement={<ErrorBoundary />}
        />
        <Route exact path='/mwfbi/about-us' component={AboutUs} />

        <Route
          exact
          path='/mwfbi/agent-details/:id'
          component={AgentDescription}
        />

        {/* phase 2 */}
        <Route exact path='/mwfbi/auto-insurance' component={AutoInsurance} />
        <Route
          exact
          path='/mwfbi/business-insurance'
          component={BusinessInsurance}
        />
        <Route exact path='/mwfbi/annuities' component={Annuities} />
        <Route
          exact
          path='/mwfbi/home-owners-insurance'
          component={HomeOwnersInsurance}
        />
        <Route exact path='/mwfbi/life-insurance' component={LifeInsurance} />
        <Route exact path='/mwfbi/other-offerings' component={OtherOfferings} />
        <Route
          exact
          path='/mwfbi/farm-&-ranch-insurance'
          component={FarmRanchInsurance}
        />
        <Route exact path='/mwfbi/contact' component={ContactUs} />
        <Route exact path='/mwfbi/agent-landing-old' component={AgentLandingScreen} />
        <Route exact path='/mwfbi/agent-landing' />
        <Route exact path='/mwfbi/agent-hub' component={AgentLanding} />
        <Route exact path='/mwfbi/customer-hub' component={CustomerHub} />
        <Route exact path='/mwfbi/internal-landing' component={InternalLandingScreen} />
        <Route exact path='/mwfbi/terms-conditions' component={TermsConditions} />
        <Route exact path='/mwfbi/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/mwfbi/under-maintenance' component={MaintenancePage} />

        {/* <Route path='*'>
          <Redirect to="/"></Redirect>
        </Route> */}
        <Route exact path='/mwfbi/preview' component={Preview} />
      </Router>
    </Fragment>
  )
}

export default App
