
import moment from 'moment-timezone';
export const TOPIC_LIST = [
    'Auto',
    'Business',
    'Events',
    'Farm & Ranch',
    'Life',
    'Home',
    'Financial',
    'Community',
    'Cyber',
    'Renters',
    'Holidays',
    'Umbrella',
    'Insurance Basics',
    'Claims'
].sort()

export const getCustomLocalDate = (date) => {
    try {
        const offset = new Date().getTimezoneOffset()
        const localDate = new Date(date);
        localDate.setMinutes(localDate.getMinutes() + offset)
        return localDate;
    } catch (error) {
        throw error
    }

}
export const getTimeZoneLocalDate = (date) => {
    try {
        // console.log( moment.tz.guess());
        // return moment.tz(date, moment.tz.guess()).add(1,'days')
        // return moment.tz(date, moment.tz.guess()).subtract(new Date().getTimezoneOffset(), 'minutes')
        return moment.utc(date).local()

    } catch (error) {
        throw error
    }

}